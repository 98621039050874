import React from 'react'
import TopNavbar from './TopNavbar'
import Modal from 'react-modal';
import LucaChat from './LucaChat';
import AskLuca from '../Askluca/Askluca'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default function Landing() {
    const [modalIsOpen, setIsOpen] = React.useState(true);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div className='toplucanavbar'>
            {/* <Modal
                isOpen={modalIsOpen}

                onRequestClose={closeModal}
                style={customStyles}

            >
                <div>
                    <div>Expertie</div>
                    <div>
                        <input
                            type="radio"
                            id="option1"
                            name="options"
                            value="Option 1"
                            checked={selectedOption === 'Option 1'}
                            onChange={handleChange}
                        />
                        <label htmlFor="option1">Option 1</label>
                    </div>
                </div>
            </Modal> */}
            {/* <LucaChat /> */}
            {/* <LucaChat /> */}
            {/* <TopNavbar /> */}
            {/*  */}
            <TopNavbar />
            {/* <Gpt /> */}
        </div>
    )
}
