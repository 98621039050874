import React, { useEffect, useState } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import "./Report.scss";

const UserSignupReport = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get("https://finacebackend.marketsverse.com/api/gettracking") // Replace with your actual API endpoint
            .then(response => {
                setData(response.data.data);
                setUsers(response.data.data); // Assuming API returns user details separately
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p className="loading">Loading...</p>;
    }
    const formatDate = (dateTime) => {
        return dateTime // Extracts only the YYYY-MM-DD part
    };
    const formatDate1 = (dateTime) => {
        return dateTime.split('T')[0]; // Extracts only the YYYY-MM-DD part
    };

    return (
        <div className="report-container">
            <h2 className="report-title">User Signup Report</h2>

            <div className="user-count-section">
                <h3>Total Users: {users?.length}</h3>
            </div>

            <div className="report-content">
                <div className="chart-card">
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={data}>
                            <XAxis dataKey={(item, i) => formatDate(i)} />
                            <YAxis />
                            <Tooltip formatter={(value, name, props) => [value, formatDate(props.payload.createdAt)]} />
                            <Bar dataKey="email" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                    {/* <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={data}>
                        <XAxis dataKey={(item) => formatDate(item.createdAt)} />
     
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="email" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer> */}
                </div>

                <div className="user-list">
                    <h3>Registered Users</h3>
                    <ul>
                        {users.map((user) => (
                            <li key={user.email}>
                                {user.email}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <table className="signup-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Number of Users</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.date}>
                            <td>{formatDate1(item.createdAt)}</td>
                            <td>{item.email}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default UserSignupReport;
